<template>
    <div>
        <div class="row">
          <div class="col-xl-3 col-sm-6">
            <div class="card card-mini mb-4">
              <div class="card-body">
                <h2 class="mb-1">{{participantesTotal}}</h2>
                <p>Participantes</p>
                <hr>
                <p>
                  <strong>{{participantesLiberados}}</strong>
                  Liberados
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
            <div class="card card-mini  mb-4">
              <div class="card-body">
                <h2 class="mb-1">{{participantesCredenciados}}</h2>
                <p>Credenciados</p>
                <hr>
                <p>
                  <strong>{{participantesTotal - participantesCredenciados}}</strong>
                  Faltante
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
            <div class="card card-mini mb-4">
              <div class="card-body">
                <h2 class="mb-1">{{participantesKits}}</h2>
                <p>Kits Entregues</p>
                <hr>
                <p>
                  <strong>{{participantesTotal - participantesKits}}</strong>
                  Faltante
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
            <div class="card card-mini mb-4">
              <div class="card-body">
                <h2 class="mb-1">{{participantesAcesso}}</h2>
                <p>Acessaram</p>
                <hr>
                <p>
                  <strong>{{participantesTotal - participantesAcesso}}</strong>
                  Faltante
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="exportar_dados">
          <div class="col-xl-3 col-sm-4">
            <container-componente label="Exportar dados">
              <select class="form-control" v-model="tipo_download">
                <option :value="o" v-for="o in opcoes" :key="o">{{o}}</option>
              </select>
            </container-componente>
            <button :disabled="!tipo_download" @click="exportar(tipo_download)" type="button" title="Download" class="btn btn-outline-primary"><i class="mdi mdi-cloud-download mr-1"></i> Download </button>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Dashbnoard',

    data(){
        return {
            tipo_download: '',
            opcoes: [
              'Participantes',
              'Participantes Liberados',
              'Participantes Nao Liberados',
              'Participantes Credenciados',
              'Participantes Nao Credenciados',
              'Kits Entregues',
              'Kits Nao Entregues',
              'Participantes Acessaram',
              'Participantes Nao Acessaram',
              'Acessou Mas Nao Retirou o Kit',
              'Retirou o Kit Mas Nao Acessou',
              'Credenciou Mas Nao Acessou',
            ]
        }
    },

    methods:{

      exportar(tipo){

        let arrData = []
        switch (tipo){
          case 'Participantes':
            arrData = this.participantes
            break;

          case 'Participantes Liberados':
            arrData = this.participantes.filter(x => x.liberado)
            break;

          case 'Participantes Nao Liberados':
            arrData = this.participantes.filter(x => !x.liberado)
            break;

          case 'Participantes Credenciados':
            arrData = this.participantes.filter(x => x.credenciado_em)
            break;

          case 'Participantes Nao Credenciados':
            arrData = this.participantes.filter(x => !x.credenciado_em)
            break;

          case 'Kits Entregues':
            arrData = this.participantes.filter(x => x.kit_retirado_em)
            break;

          case 'Kits Nao Entregues':
            arrData = this.participantes.filter(x => !x.kit_retirado_em)
            break;

          case 'Participantes Acessaram':
            arrData = this.participantes.filter(x => x.acessos.length > 0)
            break;

          case 'Participantes Nao Acessaram':
            arrData = this.participantes.filter(x => x.acessos.length == 0)
            break;

          case 'Acessou Mas Nao Retirou o Kit':
            arrData = this.participantes.filter(x => x.acessos.length > 0 && !x.kit_retirado_em)
            break;

          case 'Retirou o Kit Mas Nao Acessou':
            arrData = this.participantes.filter(x => x.acessos.length == 0 && x.kit_retirado_em)
            break;

          case 'Credenciou Mas Nao Acessou':
            arrData = this.participantes.filter(x => x.credenciado_em == 0 && x.acessos.length > 0)
            break;
        }

        if(arrData.length == 0){
          this.$confirm({
                title: 'Nenhum Registro!',
                message: `Não existe nenhum registro para listar para ${tipo} `,
                button: {
                    yes: 'Ok',
                },
                callback: confirm => {
                   
                }
                })
          return
        }

        let keys = [
          'Sequencia',
          "ID",
          "USER ID",
          "Documento",
          "Nome",
          "Sobrenome",
          "Nome no Crachá",
          "Função no Evento",
          "Email",
          "Telefone",
          "Instituição",
          "Cargo",

          "Liberado",
          "Liberado Em",
          "Liberado Por",
          "Credenciado",
          "Credenciado Em",
          
          "Kit Liberado",
          "Kit Retirado",
          "Kit Retirado Em",
    
          "CEP",
          "Rua",
          "Número",
          "Complemento",
          "Bairro",
          "Cidade",
          "Estado",
          "País",
          "Acessos"
        ]

        arrData = arrData.map(x => {
          
          return {
            sequencia: x.sequencia,
            id: x.id,
            usuario_id:x.usuario_id,
            cpf: x.cpf,
            nome: x.nome,
            sobrenome: x.sobrenome,
            apelido: x.apelido,
            funcao: x.funcao,
            email: x.email,
            telefone: x.telefone,
            instituicao: x.instituicao,
            instituicao_cargo: x.instituicao_cargo,

            liberado: x.liberado ? 'Sim' : 'Não',
            liberado_em: x.liberado_em ? moment(x.liberado_em).format('DD/MM/YYYY HH:mm:ss') : '',
            liberado_por: x.liberado_por,
            credenciado: x.credenciado_em ? 'Sim' : 'Não',
            credenciado_em: x.credenciado_em ? moment(x.credenciado_em).format('DD/MM/YYYY HH:mm:ss') : '',
            
            kit_liberado:  x.kit_liberado ? 'Sim' : 'Não',
            kit_retirado: x.kit_retirado_em ? 'Sim' : 'Não',
            kit_retirado_em: x.kit_retirado_em ? moment(x.kit_retirado_em).format('DD/MM/YYYY HH:mm:ss') : '',
      
            cep: x.cep,
            logradouro: x.logradouro,
            numero: x.numero,
            complemento: x.complemento,
            bairro: x.bairro,
            cidade: x.cidade,
            estado: x.estado,
            pais: x.pais,
            acessos: x.acessos.join(' | ')

          }
        });
      
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          keys.join(";"),
          ...arrData.map(item => Object.values(item).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", `${this.evento.titulo} - ${tipo}.csv`);
        link.click();

        setTimeout(() => {
          link.remove()
        }, 1000);
      }

    },
   
    computed:{
        ...mapGetters(['participantes','evento','exportar_dados']),

        participantesTotal(){
            return this.participantes.length
        },

        participantesCredenciados(){
            return this.participantes.filter(x => x.credenciado_em != null).length
        },

        participantesKits(){
            return this.participantes.filter(x => x.kit_retirado_em != null).length
        },

        participantesAcesso(){
            return this.participantes.filter(x => x.acessos.length > 0).length
        },

        participantesLiberados(){
            return this.participantes.filter(x => x.liberado).length
        }
    }
};
</script>
<style scoped>
    label{
        font-weight: 800;
        padding-top:5px
    }
</style>
