import { getQueryString } from '../helpers/helpers'
export class Api {

    constructor() {
        this.API_URL = window.localStorage.getItem('url');
    }

    getQueryString(object) {
       return getQueryString(object);
    }

    getHeaders(){
        return {
            headers: {
                'X-Evento-Token': `${window.localStorage.getItem('token')}`
            }
          }
    }
}