import { Api } from './Api'
import axios from 'axios'


export class PalestraService extends Api {

    constructor () {
        super();
    }

    listar(evento_id, queryString) {
      queryString = super.getQueryString(queryString);
      return axios.get(`${this.API_URL}/api/eventos/${evento_id}/palestras?${queryString}`,  this.getHeaders());
    }

    listarParticipantes(evento_id,palestra_id, queryString) {
      queryString = super.getQueryString(queryString);
      return axios.get(`${this.API_URL}/api/palestras/${palestra_id}/participantes?${queryString}`,  this.getHeaders());
    }

    atualizarParticipante(palestra_id, participante_id, obj) {
      return axios.put(`${this.API_URL}/api/palestras/${palestra_id}/participantes/${participante_id}`, obj,this.getHeaders());
    }

    

}