import { Api } from './Api'
import axios from 'axios'

export class EnderecoService extends Api {

    constructor () {
        super();
    }

  listarCidadesBusca (queryString) {
    queryString = super.getQueryString(queryString);
    return axios.get(`${this.API_URL}/api/cidades-busca?${queryString}`, this.getHeaders())
  }

  buscarCidadePorIbge(ibge){
    return axios.get(`${this.API_URL}/api/cidades-por-ibge/${ibge}`, this.getHeaders())
  }
  
  buscarEnderecoPorCep(parametro){
    let cep = parametro.replace(/\D/g, '');
    if (cep == "")
      return false;
    const validacep = /^[0-9]{8}$/;
    if(validacep.test(cep)) {
      return axios.get(`https://viacep.com.br/ws/${cep}/json/`) 
    }
   
  }

}