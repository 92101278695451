export const getQueryString = function(object) {

    if (!object)
        return ''

    if (typeof (object) === 'string')
        return object;

    if (typeof (object) === 'object')
        object = {...object}

    let queryString = ''

    Object.keys(object).forEach((key) => {
        if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
            if (queryString.length > 0)
                queryString += '&'
            queryString += key + '=' + encodeURIComponent(object[key]);
        }
    });

    return queryString;

}