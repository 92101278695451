export default {
  temCredenciais: state =>  state.url && state.token ,
  url: state => state.url,
  token: state => state.token,
  estaLogado: state => state.esta_logado,
  evento: state => state.evento,
  palestra: state => state.palestra,
  participantes: state => {
      return state.participantes
  },
  exportar_dados: state => state.exportar_dados,
  participantesPalestra: state => {
    if(!state.palestra || !state.participantes_palestra)
      return[]
      return state.participantes_palestra
  },
  modoUso: state => state.modo_uso ? state.modo_uso : "NÃO CONFIGURADO",
  ultimaSincronizacao: state => state.ultima_sincronizacao ? state.ultima_sincronizacao : "Nunca",
  configuracoes: state => state.evento.configuracoes ? state.evento.configuracoes : {},

  coresEvento: state => {
    
    if(!state.evento.configuracoes || !state.evento.configuracoes.cores)
      return {}

    return state.evento.configuracoes.cores
  },

  chaveBusca: state => {
    if(!state.evento || !state.evento.configuracoes)
    return {
        chave_busca: 'cpf',
        chave_busca_mascara: '###.###.###-##',
        chave_busca_label: 'CPF',
        chave_busca_ignorar_pontuacao: true,
        chave_busca_somente_numeros: true
      }
      
    let configuracoes = state.evento.configuracoes
    return {
      chave_busca: configuracoes.chave_busca,
      chave_busca_mascara: configuracoes.chave_busca_mascara,
      chave_busca_label: configuracoes.chave_busca_label,
      chave_busca_ignorar_pontuacao: configuracoes.chave_busca_ignorar_pontuacao,
      chave_busca_somente_numeros: configuracoes.chave_busca_somente_numeros
    }
  },

  reimprimirCracha: state => {
    if(!state.evento || !state.evento.configuracoes)
      return true

    return state.evento.configuracoes.permite_reimprimir_cracha && true
  },

  

  campos: state =>{
    if(!state.evento.configuracoes || !state.evento.configuracoes.campos)
      return []
    return state.evento.configuracoes.campos
  },

  funcoes: state => {
    if(!state.evento.configuracoes || !state.evento.configuracoes.funcoes)
      return []

      return state.evento.configuracoes.funcoes
  }

}