<template>
  <div class="px-8 py-4 " :class="{'bg-success': participante && entrada_liberada, 'bg-danger': participante && saida_liberada}">
    <div class="card card-default mb-0"  v-if="!participante">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <form class="chat-search" @submit.prevent="buscarDados()">
              <h2 class="heading-title mb-2 text-center">Código de Barras</h2>
              <input ref="textoBusca" 
                 type="tel" class="form-control form-control-lg text-center" placeholder="" 
                v-model="textoBusca"
              > <br>

              <!-- v-mask="chaveBusca.chave_busca_mascara"  -->
              <button v-show="!participante" type="submit" class="mb-1 btn btn-lg btn-primary" style="width:100%">
                <i class="font-size-36 mdi mdi-database-search"></i> BUSCAR PARTICIPANTE
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mt-4" v-if="participante && saida_liberada">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">
            <div  class="mb-5 px-6 pt-2 text-center" >
              <div class="p-4" >
                <div class="row">
                  <div class="col-md-12">
                      <h1 class="mb-60">SAÍDA LIBERADA</h1>

                      <button  @click="saida()" type="button" class="mx-4 p-4 btn btn-lg btn-danger" style="width:45%">
                        <i class="font-size-36 mdi mdi-store"></i>
                        <i class="font-size-36 mdi mdi-account-arrow-right"></i>
                         Confirmar Saída
                      </button>
                      <br>
                      <button  @click="limparDados()" type="button" class="mt-40 mx-2 p-2 btn btn-lg btn-secondary" style="width:45%">
                        <i class="font-size-36 mdi mdi-refresh"></i> Limpar Busca
                      </button>
                  </div>                   
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mt-4" v-if="participante && entrada_liberada">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">
            <div  class="mb-5 px-6 pt-2 text-center" >
              <div class="p-4" >
                <div class="row">
                  <div class="col-md-12">
                      <h1 class="mb-60">ENTRADA LIBERADA</h1>

                      <button  @click="entrada()" type="button" class="mx-4 p-4 btn btn-lg btn-success" style="width:45%">
                        <i class="font-size-36 mdi mdi-account-arrow-right"></i>
                        <i class="font-size-36 mdi mdi-store"></i>
                         Confirmar Entrada
                      </button>
                      <br>
                      <button  @click="limparDados()" type="button" class="mt-40 mx-2 p-2 btn btn-lg btn-secondary" style="width:45%">
                        <i class="font-size-36 mdi mdi-refresh"></i> Limpar Busca
                      </button>
                  </div>                   
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mt-4" v-if="participante">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <div class="mb-5 px-6 pt-2 text-center" >
              <div class="p-4" >
                <div class="row">
                  <div class="col-md-3 text-right">
                    <h2 class="mb-1" >Função:</h2>
                    <h2 class="mb-1" >Nome:</h2>
                    <h2 class="mb-1" >Nome no Crachá:</h2>

                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="mb-1" >Cidade:</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="mb-1" >Estado:</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="mb-1" >Instituição:</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="mb-1" >Cargo:</h2>

                  </div>
                  <div class="col-md-5 text-left">
                    <h2 class="heading-title mb-1">{{participante.funcao}}</h2>
                    <h2 class="heading-title mb-1">{{participante.nome}} {{participante.sobrenome}}</h2>
                    <h2 class="heading-title mb-1">{{participante.apelido}}</h2>

                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="heading-title mb-1">{{participante.cidade}}</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="heading-title mb-1">{{participante.estado}}</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="heading-title mb-1"> {{participante.instituicao}}</h2>
                    <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="heading-title mb-1">{{participante.instituicao_cargo}}</h2>

                  </div>
                  <div class="col-md-4 ">
                    <h4 class="heading-title mb-1">Últimos Acessos: </h4>
                    <ul>
                      <li v-if="!participante.acessos.length">Nenhum acesso registrado</li>
                      <li v-for="a in participante.acessos_tela" :key="a" class="justify-between">
                        <span class="badge" :class="{'badge-success': a.tipo == 'Entrada', 'badge-danger': a.tipo == 'Saida' }">{{a.tipo}}</span> 
                        <span class="float-right">{{a.data}} {{a.hora}}h</span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader  from '../components/Loader'

export default {
  name: 'ControleAcessoView',
  components:{
    Loader
  },

  data(){
    return {
      participante: null,
      
      textoBusca: '',
      loader: false
    }
  },

  mounted(){
    // this.textoBusca = '04819242997';
    // this.buscarDados();
    this.limparDados();
  },

  created(){
    window.addEventListener('keyup', (e) => {
      
      if (e.code == 'Space' && this.participante && this.entrada_liberada ) {
        // alert('entrada')
        this.entrada()
      }

      if (e.code == 'Space' && this.participante && this.saida_liberada ) {
        // alert('saida')
        this.saida()
      }      

      if (e.code == 'Escape' ) {
        this.limparDados()
      }
    });
  },

  methods:{

    tentaBuscarDados : function(key){
      if(key.key == 'Enter'){
        this.buscarDados();
      }
      if(key.key == 'Escape'){
        setTimeout(() => {              
            this.limparDados()
        }, 500);
            return false;
      }
      
    },

    limparDados () {
      this.participante = null;
      this.textoBusca = '';
      setTimeout(() => {
        this.$refs.textoBusca.focus()
      }, 200);
    },

    buscarDados (){
      let texto = this.textoBusca.toLowerCase()
      let u = this.participantes.filter(x => {
        if(this.chaveBusca.chave_busca_somente_numeros)
          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].replace(/\D/g,'') == texto.replace(/\D/g,'')) 
        if(this.chaveBusca.chave_busca_ignorar_pontuacao)
          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase().replace(/[^a-z0-9]/gi,'') == texto.replace(/[^a-z0-9]/gi,'')) 
        return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase() == texto) 
      });
       
      if(u.length >= 1){
        this.participante = u[0];
        this.participante.acessos_tela = JSON.parse(JSON.stringify(this.participante.acessos))
        this.participante.acessos_tela = this.participante.acessos_tela.reverse().slice(0,6).map(x => {
          let a = x.split(' ');
          return {
            tipo: a[0],
            data: a[1],
            hora: a[2]
          }
        })
        
      }else{
        this.$confirm({
          title: `${this.chaveBusca.chave_busca_label} Não Encontrado!`,
          message: `O ${this.chaveBusca.chave_busca_label} não encontrado! Favor dirija-se à secretaria do evento`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
      }
    },

    confirmarSaida(){
      this.$confirm({
          title: `Confirmação`,
          message: `O participante SAIU do evento?`,
          button: {
            yes: 'Sim, Participante SAIU',
            no: 'Voltar'
          },
          callback: confirm => {            
             if(confirm){
              this.saida();
             }
          }
        })
    },

    confirmarEntrada(){
      this.$confirm({
          title: `Confirmação`,
          message: `O participante ENTROU do evento?`,
          button: {
            yes: 'Sim, Participante ENTROU',
            no: 'Voltar'
          },
          callback: confirm => {            
             if(confirm){
              this.entrada();
             }
          }
        })

    },

    entradaKeyUp(e){
      if(key.key == 'Enter'){
        alert("enter")
      }
      if(key.key == 'Esc'){
        alert("esc");
            // return false;
      }

      // setTimeout(() => {              
      //       this.limparDados()
      //   }, 500);
    },

    entrada(){
      setTimeout( () => {
        this.$store.dispatch('entradaParticipante', this.participante);
        this.limparDados()
      }, 1000);
    },

    saida(){
      setTimeout( () => {
        this.$store.dispatch('saidaParticipante', this.participante);
        this.limparDados()
      }, 1000);
    }


  },

  computed: {
    ...mapGetters(['participantes','evento','chaveBusca']),
    
    entrada_liberada(){
      if(!this.participante)
        return false;

      if(!this.participante.acessos || this.participante.acessos.length == 0)
        return true

      let ultimo = this.participante.acessos[this.participante.acessos.length-1].split(' ')[0]
      console.log('Controle_entrada', ultimo)
      return (ultimo.toLowerCase() == 'saida')
    },

    saida_liberada(){
      if(!this.participante)
        return false;

      if(!this.participante.acessos || this.participante.acessos.length == 0)
        return false

      let ultimo = this.participante.acessos[this.participante.acessos.length-1].split(' ')[0]
      console.log('Controle_saida', ultimo)
      return (ultimo.toLowerCase() == 'entrada')
    }
  }
}
</script>
<style scoped>
  input{
    font-weight: 500;
    font-size: 22pt;
    color: #000000 !important;
  }

</style>
