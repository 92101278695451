<template>
  <div>
    <h1>FinanceiroView</h1>
    <Participantes></Participantes>
  </div>
</template>

<script>
import Participantes from '../components/Participantes'
export default {
  components: {
    Participantes
  },
  name: 'FinanceiroView',
}
</script>
