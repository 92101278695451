import { Api } from './Api'
import axios from 'axios'


export class ImpressaoService extends Api {

    imprimir(dados) {
      let dadosImpressao = {       
        apelido:            dados && dados.apelido_impressao ? dados.apelido_impressao: (dados && dados.apelido ? dados.apelido : '-'),
        funcao:             dados && dados.funcao_impressao ? dados.funcao_impressao: (dados && dados.funcao ? dados.funcao : '-'),
        instituicao:        dados && dados.instituicao_impressao ? dados.instituicao_impressao: (dados && dados.instituicao ? dados.instituicao : '-'),
        instituicao_cargo:  dados && dados.instituicao_cargo_impressao ? dados.instituicao_cargo_impressao: (dados && dados.instituicao_cargo ? dados.instituicao_cargo : '-'),
        cpf:                dados && dados.cpf_impressao ? dados.cpf_impressao: (dados && dados.cpf ? dados.cpf : '-'),
        sequencia:          dados && dados.sequencia_impressao ? dados.sequencia_impressao: (dados && dados.sequencia ? dados.sequencia : '-'),
        estado:             dados && dados.estado_impressao ? dados.estado_impressao: (dados && dados.estado ? dados.estado : '-'),
        cidade:             dados && dados.cidade_impressao ? dados.cidade_impressao: (dados && dados.cidade ? dados.cidade : '-'),
        modelo:             window.localStorage.getItem('impressao_modelo'),
        codigo:             dados && dados.codigo ? dados.codigo : '-'
      }

      Object.keys(dadosImpressao).forEach(key => {
        if(dadosImpressao[key] && typeof dadosImpressao[key] == 'string')
          dadosImpressao[key] = dadosImpressao[key].toUpperCase()
      })
      
      let impressao_url = window.localStorage.getItem('impressao_url');
      let impressao_metodo = window.localStorage.getItem('impressao_metodo');

      if(impressao_metodo == 'GET'){
        let queryString = super.getQueryString(dadosImpressao);
        return axios.get(`${impressao_url}?${queryString}`)
      }

      if(impressao_metodo == 'POST'){
        return axios.post(`${impressao_url}`, dadosImpressao)
      }

      
  }

  imprimirTeste(){
    let dadosTeste = {       
      apelido:            "Nome no Crachá",
      funcao:             "CREDENCIAL",
      
      instituicao:        "Instituição",
      instituicao_cargo:  "Cargo",
      cpf:                "000.000.000-00",
      sequencia:          1234,
      estado:             "PARANÁ",
      cidade:             "FOZ DO IGUAÇU",
      modelo: window.localStorage.getItem('impressao_modelo'),
      codigo:             "000000000"
    }

    return this.imprimir(dadosTeste)
  }
  


}