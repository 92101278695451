import { Api } from './Api'
import axios from 'axios'


export class ParticipanteService extends Api {

    constructor () {
        super();
    }

    listar(evento_id, queryString) {
      queryString = super.getQueryString(queryString);
      return axios.get(`${this.API_URL}/api/eventos/${evento_id}/participantes?${queryString}`,  this.getHeaders());
    }

    buscar(evento_id, participante_id){
      return axios.get(`${this.API_URL}/api/eventos/${evento_id}/participantes/${participante_id}`, this.getHeaders())
    }
  
    atualizar(evento_id, participante_id, obj)  {      
      return axios.put(`${this.API_URL}/api/eventos/${evento_id}/participantes/${participante_id}`, obj, this.getHeaders());
    }

    criar(evento_id, participante) {      
      return axios.post(`${this.API_URL}/api/eventos/${evento_id}/participantes`,participante,this.getHeaders());
    }

    liberarParticipante(evento_id, participante_id) {
      return axios.put(`${this.API_URL}/api/eventos/${evento_id}/participantes/${participante_id}/liberar`, {},  this.getHeaders());
    }

   
    
}