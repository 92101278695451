<template>
  <div class="px-8 py-4 " :class="{'bg-success': permitido === 'sim', 'bg-danger': permitido === 'nao'}">
    <Loader v-show="loading" />
    <div class="card card-default mb-20 p-4 text-center" >
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <h1>Entrega de Kits (online)</h1>
   
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mb-20" v-show="!participante">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <form class="chat-search" @submit.prevent="buscarDados()">
              <h2 class="heading-title mb-2 text-center">Código de Barras</h2>
              <input type="text" class="form-control form-control-lg text-center" placeholder="" 
              ref="textoBusca"
               v-model="textoBusca"> <br>

              <!-- v-mask="chaveBusca.chave_busca_mascara" -->
              <button v-show="!participante" type="submit" class="py-4 mb-1 btn btn-lg btn-primary" style="width:100%">
                <i class="font-size-36 mdi mdi-database-search"></i> Buscar Dados
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mb-20" v-if="participante">
      <div class="row bg-white no-gutters chat" >
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">
            <div class="row">
              <div class="col-md-12 text-center pt-8">

                <h3 class="heading-title mb-2">
                    {{participante.funcao}}
                  </h3>
                  <h1 class="heading-title mb-2">
                    {{participante.nome}} {{participante.sobrenome}}
                  </h1>

                  <h2 class="heading-title mb-1">{{participante.apelido_impressao}}</h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="heading-title mb-1">{{participante.cidade_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="heading-title mb-1">{{participante.estado_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="heading-title mb-1"> {{participante.instituicao_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="heading-title mb-1">{{participante.instituicao_cargo_impressao}}</h2>


                <div v-if="permitido == 'sim'" class="alert alert-success mt-8"><h1>Entrega de Kit Liberada</h1></div>
                <div v-if="permitido == 'nao'" class="alert alert-danger mt-8">
                    <h1 v-if="!participante.kit_liberado"> Não consta liberação de kit no sistema para este participante!</h1>
                    <h1 v-if="participante.kit_retirado_em != null"> O Kit consta como retirado por este participante em {{ participante.kit_retirado_em | timeformat }} !</h1>
                </div>
              </div>

            </div>

            <div class="row">
                <div class="col-md-12 text-center mt-5 flex justify-between">
                    <button :disabled="permitido == 'nao'" type="button" class="mx-2 py-4 mb-1 btn btn-lg btn-success" style="width:40%" @click="confirmarEntrega()">
                        <i class="font-size-36 mdi mdi-check-circle"></i>  Confirmar Entrega
                    </button>
                    <button v-show="participante" @click="limparDados()" type="button" class="mx-2 py-4 mb-1 btn btn-lg btn-secondary" style="width:40%">
                      <i class="font-size-36 mdi mdi-refresh"></i> Voltar
                    </button>
                </div>
              </div>

          </div>
        </div>
      </div>
    </div>


    
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Loader from '../components/Loader.vue'
import { ParticipanteService } from '../services'
import moment from 'moment'
const participanteService = new ParticipanteService();

export default {
  name: 'PalestraView',
  components:{
    Loader
  },
  data() {
    return {
      loading: false,
      participante: null,
      textoBusca: '' //'82817278020'
    }
  },

  methods:{
    buscarDados: function(){
        let texto = this.textoBusca.toLowerCase()
        let u = this.participantes.filter(x => {
          if(this.chaveBusca.chave_busca_somente_numeros)
            return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].replace(/\D/g,'') == texto.replace(/\D/g,'')) 
          if(this.chaveBusca.chave_busca_ignorar_pontuacao)
            return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase().replace(/[^a-z0-9]/gi,'') == texto.replace(/[^a-z0-9]/gi,'')) 

          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase() == texto) 
        });
        
        if(u.length >= 1){
          
          this.buscarDadosAtualizados({...u[0]})
         
        }else{
          this.$confirm({
          title: `${this.chaveBusca.chave_busca_label} Não Encontrado!`,
          message: `O ${this.chaveBusca.chave_busca_label} não encontrado! Favor dirija-se à secretaria do evento`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
      }

    },

    buscarDadosAtualizados(participante){
      this.loading = true;
      participanteService.buscar(participante.evento_id, participante.id)
        .then(response => {
          this.participante = response.data
        })
        .catch( error => {
          this.$confirm({
          title: `Não foi possível validar os dados no servidor`,
          message: `Verifique a sua conexão com a internet, se o erro persistir! Favor dirija-se à secretaria do evento`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 100);
            
          }
          })
        })
        .finally( () => this.loading = false )
      
    },


    confirmarEntrega(){
      this.$confirm({
          title: `Confirmação de Entrega `,
          message: `Confirmar Entrega de Kit a ${this.participante.apelido}!`,
          button: {
            yes: 'Sim',
            no: 'Não',
          },
          callback: confirm => {            
            if(confirm)
              this.entregar();
            else
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
    },

    entregar(){
      let form = { kit_retirado_em: moment().format('YYYYMMDDHHmmss') }
      this.loading = true;

      participanteService.atualizar(this.participante.evento_id, this.participante.id, form)
      .then( response => {
          this.$store.dispatch('entregaKitParticipante', this.participante);
      })
      .catch( error => {
        this.$confirm({
          title: `ERRO! Entrega Não Confirmada!`,
          message: `Verifique Novamente, ocorreu um erro durante a confirmação e a entrega pode não ter sido confirmada!`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 100);
            
          }
          })
      })
      .finally( () => {
        this.loading = false;
        setTimeout(() => {
          this.limparDados()
        }, 100);

      })


    },

    limparDados () {
      this.participante = null;
      this.textoBusca = '';
      setTimeout(() => {
        this.$refs.textoBusca.focus()
      }, 200);

    },
  },

  computed: {
    ...mapGetters(['participantes','evento','chaveBusca','palestra','participantesPalestra']),

    permitido(){
      if(!this.participante)
        return '';

      if(!this.participante.kit_liberado)
        return 'nao'

      if(this.participante.kit_retirado_em)
        return 'nao'

      return 'sim'
    }
  }
}
</script>
