import moment from 'moment';
export default {

    credenciouParticipante (store, participante){
        let participantes = store.state.participantes
        let index = participantes.map(x => x.id).indexOf(participante.id);
        if(index < 0){
            participante.credenciado_em = moment().format('YYYY-MM-DD HH:mm:ss');
            participante.sincronizar = true;
            participantes.push(participante)
        }else{
            participantes[index].credenciado_em = moment().format('YYYY-MM-DD HH:mm:ss');
            participantes[index].sincronizar = true;
        }
        store.commit('PARTICIPANTES', participantes);
    },

    entregaKitParticipante (store, participante){
        let participantes = store.state.participantes
        let index = participantes.map(x => x.id).indexOf(participante.id);
        if(index < 0){
            participante.kit_retirado_em = moment().format('YYYY-MM-DD HH:mm:ss');
            participante.sincronizar = true;
            participantes.push(participante)
        }else{
            participantes[index].kit_retirado_em = moment().format('YYYY-MM-DD HH:mm:ss');
            participantes[index].sincronizar = true;
        }
        store.commit('PARTICIPANTES', participantes);
    },

    entradaParticipante(store, participante){
        let participantes = store.state.participantes
        let index = participantes.map(x => x.id).indexOf(participante.id);
        let dado = 'Entrada ' + moment().format('DD/MM/YYYY HH:mm:ss');
        if(index < 0){
            participante.sincronizar = true;
            participante.acessos.push(dado);
            participantes.push(participante)
        }else{
            participantes[index].acessos.push(dado);
            participantes[index].sincronizar = true;
        }
        store.commit('PARTICIPANTES', participantes);
    },

    saidaParticipante(store, participante){
        let participantes = store.state.participantes
        let index = participantes.map(x => x.id).indexOf(participante.id);
        let dado = 'Saida ' + moment().format('DD/MM/YYYY HH:mm:ss');
        if(index < 0){
            participante.sincronizar = true;
            participante.acessos.push(dado);
            participantes.push(participante)
        }else{
            participantes[index].acessos.push(dado);
            participantes[index].sincronizar = true;
        }
        store.commit('PARTICIPANTES', participantes);
    },
        
}