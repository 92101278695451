import { mapGetters } from 'vuex';

const MixinCongressoft = {
    computed: {
        ...mapGetters(['estaLogado','temCredenciais','url','login', 'senha','evento','modoUso','ultimaSincronizacao','palestra']),
        conectado(){
            return this.estaLogado
          }
    },
    methods: {
      
    }
  }
  
  
  export default MixinCongressoft;