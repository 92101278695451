<template>
  <div>
    <component :is="componente" v-if="componente != null" />
    <div v-else class="alert alert-danger" role="alert">
        Selecione um modo de uso na página de configurações
      </div>
      <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
import SecretariaView from './SecretariaView.vue'
import CadastroView from './CadastroView.vue'
import FinanceiroView from './FinanceiroView.vue'
import PalestraView from './PalestraView.vue'
import EntregaView from './EntregaView.vue'
import EntregaOnlineView from './EntregaOnlineView.vue'
import ControleAcessoView from './ControleAcessoView'

import { mapGetters } from 'vuex'
export default {
  name: 'HomeView',
  components: {
    SecretariaView,
    CadastroView,
    FinanceiroView,
    PalestraView,
    EntregaView,
    EntregaOnlineView,
    ControleAcessoView
  },

  computed:{
    ...mapGetters(['modoUso']
    ),

    componente(){
      switch(this.modoUso){
        case 'SECRETARIA': return SecretariaView
        case 'CADASTRO': return CadastroView
        case 'FINANCEIRO': return FinanceiroView
        case 'PALESTRA': return PalestraView
        case 'ENTREGA - OFFLINE': return EntregaView
        case 'ENTREGA - ONLINE': return EntregaOnlineView
        case 'CONTROLE ACESSO': return ControleAcessoView
      }
      return null
    }
  }
}
</script>

<style>
  
  .vc-title {
      color: black !important;
       padding: 0 2rem !important;
       width: 100% !important;
       font-weight: 600 !important;
       text-align: center !important;
       font-size: 32px !important;
       line-height: initial !important;
       margin-bottom: 15px !important;
  } 
  .vc-text {
      color: black !important;
       padding: 0 2rem !important;
       width: 100% !important;
       font-weight: 500 !important;
       text-align: center !important;
       font-size: 28px !important;
       line-height: initial !important;
  } 
  .vc-overlay {
      background: rgba(0, 0, 0, 0.29);
       width: 100%;
       height: 100%;
       transition: all 0.1s ease-in;
       left: 0;
       top: 0;
       z-index: 999999999999;
       position: fixed;
       display: flex;
       justify-content: center;
       align-items: center;
       align-content: baseline;
    } 
  .vc-container {
      background: white;
       border-radius: 1rem;
       width: 550px !important;
       height: auto;
       display: grid;
       grid-template-rows: 1fr auto;
       box-shadow: rgba(0, 0, 0, 0.29) 0px 3px 8px 0px;
       } 
  .vc-text-grid {
      padding: 1rem;
       } 
  .vc-btn-grid {
      width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr;
       height: 70px;
       border-radius: 0 0 1rem 1rem;
       overflow: hidden;
  } 
  .vc-btn-grid.isMono {
      grid-template-columns: 1fr;
  } 
  .vc-btn {
      border-radius: 0 0 1rem 0;
       color: cornflowerblue;
       background: white;
       border: 0;
       font-size: 1.5rem !important;
       border-top: 1px solid rgb(224, 224, 224);
       cursor: pointer;
       font-weight: 700;
       outline: none;
       } 
  .vc-btn:hover {
      background: whitesmoke;
       } 
  .vc-btn:disabled {
      background: whitesmoke;
       } 
  .vc-btn:active {
      box-shadow: inset 0 2px 0px 0px #00000014;
       } 
  .vc-btn.left {
      border-radius: 0;
       /* color: black;
       */ border-right: 1px solid #e0e0e0;
       } 
  .vc-input[type='password'] {
      width: 100%;
       outline: none;
       border-radius: 8px;
       height: 35px;
       border: 0;
       margin: 5px 0;
       background-color: #ebebeb;
       padding: 0 0.5rem;
       font-size: 28px;
       transition: 0.21s ease;
       } 
  .vc-input[type='password']:hover, .vc-input[type='password']:focus {
      background-color: #dfdfdf;
       }
</style>
