export default {
    url: localStorage.url ? localStorage.url : 'https://congressoft.com',
    token: localStorage.token ? localStorage.token : null,
    modo_uso: localStorage.modo_uso ? localStorage.modo_uso : 'SECRETARIA', // PALESTRA // SECRETARIA // CADASTRO
    exportar_dados: localStorage.exportar_dados ? JSON.parse(localStorage.exportar_dados) : false,
    esta_logado: localStorage.esta_logado ? JSON.parse(localStorage.esta_logado) : false,
    palestra: localStorage.palestra ? JSON.parse(localStorage.palestra) : {},
    evento: localStorage.evento ? JSON.parse(localStorage.evento) : {},
    participantes: localStorage.participantes ? JSON.parse(localStorage.participantes) : [],
    participantes_palestra: localStorage.participantes_palestra ? JSON.parse(localStorage.participantes_palestra) : [],
    ultima_sincronizacao: localStorage.ultima_sincronizacao,

    impressao_metodo: localStorage.impressao_metodo ? localStorage.impressao_metodo : 'GET',
    impressao_modelo: localStorage.impressao_modelo ? localStorage.impressao_modelo : '1',
    impressao_url: localStorage.impressao_url ? localStorage.impressao_url : 'http://localhost:5000/print',
    
  }