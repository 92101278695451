<template>
  <div  >
    <div class="wrapper">
      <div class="page-wrapper">
        <div class="content-wrapper">
          <div class="content">
            <router-view/>
        </div>
      </div>
      <!-- Footer -->
      <footer class="footer mt-auto">
        <div class="mb-2 bg-white text-center">
          <hr>
             Função: {{modoUso}} | Ultima Sincronização {{ultimaSincronizacao | timeformat}}
            <span v-if="evento.titulo">| Evento: {{evento.titulo}}</span>
            <span v-if="palestra.titulo">| Palestra: {{palestra.titulo}}</span>
        </div>
      </footer>

      </div>
    </div>
  </div>
</template>

<script>
import mixinDados from './mixins'

export default {
  name: 'App',
  mixins: [mixinDados],
  created() {
    window.addEventListener('keyup', (e) => {
      if (e.code == 'F8' ) {
        this.$router.push('/configuracoes')
      }
    });
  },
}
</script>


    
<style>
@import '~bootstrap/dist/css/bootstrap.css';
@import './assets/css/sleek.css';
body{
  background-color: #f5f6fa !important
}
</style>
