<template>
  <div class="px-8 py-4 " :class="{'bg-success': participante && participante.acesso_liberado, 'bg-danger':  participante && !participante.acesso_liberado}">

    <div class="card card-default mb-20 p-4 text-center" >
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <p>Controle de Acesso</p>
            <h1>{{palestra.nome}}</h1>
   
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mb-20" v-show="!participante">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <form class="chat-search" @submit.prevent="buscarDados()">
              <h2 class="heading-title mb-2 text-center">Digite seu {{chaveBusca.chave_busca_label}}</h2>
              <input type="text" class="form-control form-control-lg text-center" placeholder="" v-mask="chaveBusca.chave_busca_mascara" v-model="textoBusca"> <br>

              <button v-show="!participante" type="submit" class="py-4 mb-1 btn btn-lg btn-primary" style="width:100%">
                <i class="font-size-36 mdi mdi-database-search"></i> VERIFICAR ACESSO
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default mb-20" v-if="participante">
      <div class="row bg-white no-gutters chat" >
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">
            <div class="row">
              <div class="col-md-12 text-center pt-8">

                <h3 class="heading-title mb-2">
                    {{participante.funcao}}
                  </h3>
                  <h1 class="heading-title mb-2">
                    {{participante.nome}} {{participante.sobrenome}}
                  </h1>

                  <h2 class="heading-title mb-1">{{participante.apelido_impressao}}</h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="heading-title mb-1">{{participante.cidade_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="heading-title mb-1">{{participante.estado_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="heading-title mb-1"> {{participante.instituicao_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="heading-title mb-1">{{participante.instituicao_cargo_impressao}}</h2>


                <div v-if="participante.acesso_liberado" class="alert alert-success mt-8"><h1>Acesso Liberado</h1></div>
                <div v-if="!participante.acesso_liberado" class="alert alert-danger mt-8"><h1>Não consta na lista de liberados!</h1></div>
              </div>

            </div>

            <div class="row">
                <div class="col-md-12 text-center mt-5 flex justify-between">
                    <button :disabled="!participante.acesso_liberado" type="button" class="mx-2 py-4 mb-1 btn btn-lg btn-success" style="width:40%" @click="abrirTelaConfirmarAcesso()">
                        <i class="font-size-36 mdi mdi-check-circle"></i>  Confirmar Acesso
                    </button>
                    <button v-show="participante" @click="limparDados()" type="button" class="mx-2 py-4 mb-1 btn btn-lg btn-secondary" style="width:40%">
                      <i class="font-size-36 mdi mdi-refresh"></i> Voltar
                    </button>
                </div>
              </div>

          </div>
        </div>
      </div>
    </div>


    
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
export default {
  name: 'PalestraView',
  data() {
    return {
      participante: null,
      textoBusca: ''//'232.527.829-87'
    }
  },

  methods:{
    buscarDados: function(){
        let texto = this.textoBusca.toLowerCase()
        let u = this.participantes.filter(x => {
          if(this.chaveBusca.chave_busca_somente_numeros)
            return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].replace(/\D/g,'') == texto.replace(/\D/g,'')) 
          if(this.chaveBusca.chave_busca_ignorar_pontuacao)
            return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase().replace(/[^a-z0-9]/gi,'') == texto.replace(/[^a-z0-9]/gi,'')) 

          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase() == texto) 
        });
        
        if(u.length >= 1){
          this.participante = {...u[0], acesso_liberado: true};

          this.buscarDadosPalestra();
                   
        }else{
          this.$confirm({
          title: `${this.chaveBusca.chave_busca_label} Não Encontrado!`,
          message: `O ${this.chaveBusca.chave_busca_label} não encontrado! Favor dirija-se à secretaria do evento`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
      }

    },

    buscarDadosPalestra(){
      if(this.palestra.limite_participantes > 0){
        console.log("Implementar controle de limite de acesso")
        //this.participante.acesso_liberado = this.participantes_palestra.map(x => x.id).indexOf(this.participante.id) >= 0
      }

      if(this.palestra.evento_fechado){
        this.participante.acesso_liberado = this.participantesPalestra.map(x => x.id).indexOf(this.participante.id) >= 0
        
      }else{
        this.$confirm({
        title: `${this.chaveBusca.chave_busca_label} Não Encontrado!`,
        message: `O ${this.chaveBusca.chave_busca_label} não encontrado! Favor dirija-se à secretaria do evento`,
        button: {
          yes: 'Ok'
        },
        callback: confirm => {            
            setTimeout(() => {     
              this.limparDados()
            }, 500);
          
        }})
      }
    },

    abrirTelaConfirmarAcesso(){
      this.$confirm({
          title: `Confirmação de Acesso `,
          message: `Confirmar Acesso de ${this.participante.apelido}!`,
          button: {
            yes: 'Sim',
            no: 'Não',
          },
          callback: confirm => {            
            if(confirm)
              this.confirmarAcesso();
            else
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
    },

    confirmarAcesso(){
      let index = this.participantesPalestra.map(x => Number(x.id)).indexOf(Number(this.participante.id));
      if(index >= 0){
        this.participantesPalestra[index].hora_entrada = moment().format('YYYY-MM-DD HH:mm:ss');
      }else{
        this.participante.hora_entrada = moment().format('YYYY-MM-DD HH:mm:ss');
        this.participantesPalestra.push({...this.participante})
      }

      this.$store.commit('PARTICIPANTES_PALESTRA', this.participantesPalestra);

      setTimeout(() => {
        this.limparDados()
      }, 500);
    },

    limparDados () {
      this.participante = null;
      this.textoBusca = ''
    },
  },

  computed: {
    ...mapGetters(['participantes','evento','chaveBusca','palestra','participantesPalestra'])
  }
}
</script>
