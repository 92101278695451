import moment from 'moment'

export default {
    ['URL'] (state, url) {
        state.url = url
        localStorage.url = url;
    },
    ['TOKEN'] (state, token) {
        state.token = token
        localStorage.token = token
    },
    ['ESTA_LOGADO'] (state, esta_logado) {
        state.esta_logado = esta_logado
        localStorage.esta_logado = esta_logado
    },
    ['ULTIMA_SINCRONIZACAO'] (state, ultima_sincronizacao) {
        state.ultima_sincronizacao = ultima_sincronizacao
        localStorage.ultima_sincronizacao = ultima_sincronizacao;
    },

    ['EVENTO'] (state, evento) {
        state.evento = evento
        localStorage.evento = JSON.stringify(evento);
    },

    ['PALESTRA'] (state, palestra) {
        state.palestra = palestra
        localStorage.palestra = JSON.stringify(palestra);
    },

    ['EXPORTAR_DADOS'] (state, payload) {
        state.exportar_dados = payload
        localStorage.exportar_dados = payload;
    },

    ['PARTICIPANTES'] (state, participantes) {
        state.participantes = participantes
        localStorage.participantes = JSON.stringify(participantes);
    },   

    ['PARTICIPANTES_PALESTRA'] (state, participantes_palestra) {
        state.participantes_palestra = participantes_palestra
        localStorage.participantes_palestra = JSON.stringify(participantes_palestra);
    },

    ['IMPRESSAO_URL'] (state, impressao_url) {
        state.impressao_url = impressao_url
        localStorage.impressao_url = impressao_url;
    },

    ['IMPRESSAO_METODO'] (state, impressao_metodo) {
        state.impressao_metodo = impressao_metodo
        localStorage.impressao_metodo = impressao_metodo;
    },

    ['IMPRESSAO_MODELO'] (state, impressao_modelo) {
        state.impressao_modelo = impressao_modelo
        localStorage.impressao_modelo = impressao_modelo;
    },
    ['MODO_USO'] (state, modo_uso) {
        state.modo_uso = modo_uso
        localStorage.modo_uso = modo_uso;
    }
}